import { createIntl, createIntlCache } from 'react-intl';
import { IMAGE_STORAGE, SORA_TOKEN, TOAST_ID } from '../constants';
import multipliers from './multipliers';
import { showErrorNotify, showSuccessNotify } from './toast';
import { Keyring } from '@polkadot/api';

import moment from 'moment';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: 'en-US',
  },
  cache
);

export const getActivePermalink = (pathname, permalink) => {
  let locationPermalink = pathname?.substring(
    pathname?.indexOf('/'),
    pathname?.length
  );

  if (locationPermalink.lastIndexOf('/') !== 0) {
    locationPermalink = locationPermalink?.substring(
      locationPermalink?.indexOf('/'),
      locationPermalink?.lastIndexOf('/')
    );
  }

  return locationPermalink === permalink;
};

export const priceFormat = (price, decimals = 2) => {
  if (price !== null) {
    return intl.formatNumber(price, {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    });
  }

  return '0.00';
};

export const getAvatarTitle = (accountName) => {
  if (accountName !== null) {
    const nameSplit = accountName?.split(' ');

    if (nameSplit?.length > 1) {
      return (
        nameSplit[0].charAt(0).toUpperCase() +
        nameSplit[1].charAt(0).toUpperCase()
      );
    }

    return accountName?.charAt(0);
  }

  return '';
};

export const formatWalletAddress = (address, formatDelimiter = 10) => {
  if (address && address.length > 20) {
    return `${address.substring(0, formatDelimiter)}-${address.substring(
      address.length - formatDelimiter,
      address.length
    )}`;
  }

  return '';
};

export const parse = (data) => {
  if (data !== null && typeof data === 'string') {
    data = data?.split(' ');
    return data.length !== 1
      ? (parseFloat(data[0]) * multipliers[data[1]]).toFixed(2)
      : parseFloat(data[0]).toFixed(2);
  }

  return 0;
};

export const getTokenIcon = (token) => {
  return `${IMAGE_STORAGE}${token}.svg`;
};

export const validateDateInFutureInMinutes = (date, difference = 15) => {
  const validationDate = moment(date, 'YYYY-MM-DD HH:MM');
  const now = moment(new Date(), 'YYYY-MM-DD HH:MM');
  const duration = moment.duration(validationDate.diff(now));
  const minutes = duration.asMinutes();

  if (minutes >= difference) {
    return true;
  }

  return false;
};

export const copyText = async (text) => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text);
    showSuccessNotify(`Copied: ${text}`, TOAST_ID);
  }
};

export const formatDateFromTimestamp = (
  timestamp,
  multiplyByThousand = true
) => {
  const multiplier = multiplyByThousand ? 1000 : 1;
  const date = moment(new Date(timestamp * multiplier), 'YYYY-MM-DD HH:MM');
  return `${date.format('MMMM DD, YYYY')} ${date.format('HH:mm')}`;
};

export const formatDateToTimestamp = (date, miliseconds = false) => {
  if (miliseconds) {
    return moment(date).format('x');
  }

  return moment(date).format('X');
};

export const formatDateTimeFromTimestamp = (timestamp) => {
  const t = timestamp?.toString()?.replaceAll(',', '');

  const date = moment(t, 'x').toDate();
  const dateFormatted = `${moment(t, 'x').format('MMMM DD')}, ${moment(
    t,
    'x'
  ).format('yyyy')}`;
  const timeFormatted = `${moment(t, 'x').format('HH')}:${moment(t, 'x').format(
    'mm'
  )}h`;
  const now = new Date();
  const expired = now - date >= 0;

  return {
    date: dateFormatted,
    time: timeFormatted,
    expired: expired,
  };
};

export const getNumberOfDaysFromTimestamp = (timestamp) => {
  const t = timestamp?.toString()?.replaceAll(',', '');
  const miliseconds = parseFloat(t);
  return Math.floor(miliseconds / 1000 / 60 / 60 / 24);
};

export const formatDateFromDateTime = (date) => {
  const d = moment(date, 'YYYY-MM-DD HH:MM');
  return `${d.format('MMMM DD, YYYY')} ${d.format('HH:mm')}`;
};

export const getEncodedAddress = (address) => {
  if (address) {
    const keyring = new Keyring();
    return keyring.encodeAddress(address, 69);
  }

  return '';
};

export const formatNumberFromMultiplier = (
  value,
  symbol = SORA_TOKEN,
  multiplier = 1
) => {
  return `${
    (parseFloat(value?.replaceAll(',', '')) / Math.pow(10, 18)) * multiplier
  } ${symbol ?? ''}`;
};

export const getNumberFromMultiplier = (value) => {
  const number = parseFloat(value?.replaceAll(',', '')) / Math.pow(10, 18);

  return number < 0 ? 0.0 : number;
};

export const replaceAllCharactersInStringNumber = (number) => {
  return number?.toString()?.replaceAll(',', '');
};

export const generateErrorMessage = (error, returnMessage = false) => {
  let err = '';

  if (error?.data?.message && error?.data?.message !== '') {
    err = error?.data?.message;
  } else if (error?.message && error?.message !== '') {
    err = error?.message;
  } else {
    err = 'Error: Action cannot be performed.';
  }

  if (err.includes('revert') && err.split('revert').length === 2) {
    err = err.split('revert')[1].trim();
  }

  if (returnMessage) {
    return err;
  }

  showErrorNotify(err, TOAST_ID);
};
