import React, { useState } from 'react';

import Logo from '../../assets/svg/logo.svg';
import Menu from '../../assets/svg/menu.svg';
import Close from '../../assets/svg/close.svg';
import LogoNoText from '../../assets/svg/logo_no_text.svg';
import LogoWhite from '../../assets/svg/logo_white.svg';
import ArrowDown from '../../assets/svg/arrow_down.svg';
import Wallet from '../../assets/svg/wallet.svg';

import usePolkadot from '../../hooks/use_polkadot';
import {
  formatWalletAddress,
  getAvatarTitle,
  priceFormat,
} from '../../utils/helpers';

import * as Styled from './header.styled';

import Tippy from '@tippyjs/react';
import { SpacerHorizontal, SpacerVertical } from '../spacer/spacer';

import { useMediaQuery } from 'react-responsive';
import { device } from '../../styles/device';
import {
  LAUNCHPAD_PERMALINK,
  POLKADOT_EXTENSION,
  SORA,
  TOAST_ID,
  TOKEN_NAME,
  WALLET_DISABLED,
} from '../../constants';

import { useNavigate, useLocation } from 'react-router-dom';
import { showErrorNotify } from '../../utils/toast';

const Header = ({ sidebarOpen, openSidebar, closeSidebar }) => {
  const [visible, setVisible] = useState(false);
  const [networkMenuVisible, setNetworkMenuVisible] = useState(false);
  const [walletSelectDisabled, setWalletSelectDisabled] = useState(false);

  const isLaptop = useMediaQuery({ query: device.laptop });
  const isMobileXL = useMediaQuery({ query: device.mobileXL });
  const isMobileL = useMediaQuery({ query: device.mobileL });
  const isMobileM = useMediaQuery({ query: device.mobileM });

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    ceresPrice,
    accounts,
    selectedAccount,
    saveSelectedAccount,
    networkOptions,
    network,
    switchNetwork,
    connectToMetamask,
  } = usePolkadot();

  const closeMenu = () => {
    setVisible(false);
  };

  const openMenu = () => {
    setVisible(true);
  };

  const closeNetworkMenu = () => {
    setNetworkMenuVisible(false);
  };

  const openNetworkMenu = () => {
    setNetworkMenuVisible(true);
  };

  const walletEnabled = !pathname.includes(LAUNCHPAD_PERMALINK);

  return (
    <Styled.Wrapper>
      {isMobileL ? (
        <Styled.CeresPriceContainer>
          <Styled.CeresPriceLabel>{`${TOKEN_NAME} $${priceFormat(
            ceresPrice,
            3
          )}`}</Styled.CeresPriceLabel>
        </Styled.CeresPriceContainer>
      ) : null}
      <Styled.Container>
        <Styled.LogoContainer>
          {isLaptop ? (
            <>
              {sidebarOpen ? (
                <Styled.MenuIcon src={Close} onClick={() => closeSidebar()} />
              ) : (
                <Styled.MenuIcon src={Menu} onClick={() => openSidebar()} />
              )}
              <SpacerVertical size={isMobileM ? 20 : 50} />
            </>
          ) : null}
          {!isMobileM ? (
            <Styled.Logo src={Logo} onClick={() => navigate('/')} />
          ) : (
            <Styled.Logo src={LogoWhite} onClick={() => navigate('/')} />
          )}
        </Styled.LogoContainer>
        <Styled.ButtonsContainer>
          {!isMobileL ? (
            <>
              <Styled.CeresContainer>
                <Styled.CeresLogoContainer>
                  <Styled.Logo src={LogoNoText} />
                </Styled.CeresLogoContainer>
                <Styled.CeresPrice>
                  <Styled.CeresPriceSymbol>$</Styled.CeresPriceSymbol>
                  {priceFormat(ceresPrice, 3)}
                </Styled.CeresPrice>
              </Styled.CeresContainer>
              <SpacerVertical size={15} />
            </>
          ) : null}
          <Tippy
            visible={networkMenuVisible}
            content={
              <NetworkMenu
                networkOptions={networkOptions}
                network={network}
                setWalletSelectDisabled={setWalletSelectDisabled}
                switchNetwork={switchNetwork}
                closeNetworkMenu={closeNetworkMenu}
              />
            }
            animation='fade'
            onClickOutside={() => closeNetworkMenu()}
            placement={'bottom-end'}
            appendTo='parent'
            interactive='true'
            duration={0}
            maxWidth='none'
            offset={[0, 40]}
          >
            <Styled.WalletButtonContainerAccount
              onClick={() => openNetworkMenu()}
            >
              <Styled.CeresLogoContainer>
                <Styled.NetworkLogo src={network.logo} />
              </Styled.CeresLogoContainer>
              {!isMobileXL ? (
                <>
                  <SpacerVertical size={10} />
                  <Styled.WalletButtonAccountTitle>
                    {network?.name}
                  </Styled.WalletButtonAccountTitle>
                </>
              ) : null}
              {!isMobileM ? (
                <>
                  <SpacerVertical size={10} />
                  <Styled.WalletButtonAccountArrow
                    src={ArrowDown}
                    visible={networkMenuVisible}
                  />
                </>
              ) : null}
            </Styled.WalletButtonContainerAccount>
          </Tippy>
          <SpacerVertical size={isMobileM ? 10 : 15} />
          {network.id === SORA ? (
            <Tippy
              visible={visible}
              disabled={!walletEnabled || walletSelectDisabled}
              content={
                <WalletMenu
                  accounts={accounts}
                  selectedAccount={selectedAccount}
                  saveSelectedAccount={saveSelectedAccount}
                  closeMenu={closeMenu}
                />
              }
              animation='fade'
              onClickOutside={() => closeMenu()}
              placement={'bottom-end'}
              appendTo='parent'
              interactive='true'
              duration={0}
              maxWidth='none'
              offset={[0, 40]}
            >
              {selectedAccount ? (
                <Styled.WalletButtonContainerAccount
                  onClick={() => {
                    if (walletEnabled && !walletSelectDisabled) {
                      openMenu();
                    } else {
                      showErrorNotify(WALLET_DISABLED, TOAST_ID);
                    }
                  }}
                >
                  <Styled.CeresLogoContainer>
                    <Styled.WalletMenuAvatarTitle>
                      {getAvatarTitle(selectedAccount?.meta?.name)}
                    </Styled.WalletMenuAvatarTitle>
                  </Styled.CeresLogoContainer>
                  {!isMobileXL ? (
                    <>
                      <SpacerVertical size={10} />
                      <Styled.WalletButtonAccountTitle>
                        {selectedAccount?.meta?.name}
                      </Styled.WalletButtonAccountTitle>
                    </>
                  ) : null}
                  {!isMobileM ? (
                    <>
                      <SpacerVertical size={10} />
                      <Styled.WalletButtonAccountArrow
                        src={ArrowDown}
                        visible={visible}
                      />
                    </>
                  ) : null}
                </Styled.WalletButtonContainerAccount>
              ) : (
                <Styled.WalletButtonContainer
                  onClick={() => {
                    if (walletEnabled && !walletSelectDisabled) {
                      openMenu();
                    } else {
                      showErrorNotify(WALLET_DISABLED, TOAST_ID);
                    }
                  }}
                >
                  {!isMobileXL ? (
                    <Styled.WalletButtonLabel>
                      Connect wallet
                    </Styled.WalletButtonLabel>
                  ) : (
                    <Styled.CeresLogoContainer>
                      <Styled.WalletButtonIcon src={Wallet} />
                    </Styled.CeresLogoContainer>
                  )}
                </Styled.WalletButtonContainer>
              )}
            </Tippy>
          ) : selectedAccount ? (
            <Styled.WalletButtonContainerAccount>
              <Styled.CeresLogoContainer>
                <Styled.WalletMenuAvatarTitle>
                  <Styled.WalletButtonIcon src={Wallet} />
                </Styled.WalletMenuAvatarTitle>
              </Styled.CeresLogoContainer>
              <SpacerVertical size={10} />
              <Styled.WalletButtonAccountTitle>
                {formatWalletAddress(
                  selectedAccount?.address,
                  isMobileXL ? 3 : 5
                )}
              </Styled.WalletButtonAccountTitle>
            </Styled.WalletButtonContainerAccount>
          ) : (
            <Styled.WalletButtonContainer
              onClick={() => {
                if (!walletSelectDisabled) {
                  connectToMetamask();
                }
              }}
            >
              {!isMobileXL ? (
                <Styled.WalletButtonLabel>
                  Connect wallet
                </Styled.WalletButtonLabel>
              ) : (
                <Styled.CeresLogoContainer>
                  <Styled.WalletButtonIcon src={Wallet} />
                </Styled.CeresLogoContainer>
              )}
            </Styled.WalletButtonContainer>
          )}
        </Styled.ButtonsContainer>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

const WalletMenu = ({
  accounts,
  selectedAccount,
  saveSelectedAccount,
  closeMenu,
}) => {
  const isMobileS = useMediaQuery({ query: device.mobileS });

  return (
    <Styled.WalletMenuContainer isMobileS={isMobileS}>
      <Styled.WalletMenuTitle>Connected wallet</Styled.WalletMenuTitle>
      <SpacerHorizontal size={25} />
      {!accounts ? (
        <Styled.WalletMenuNoWallet>
          Please add wallet using Polkadot JS extension. If you don't have
          Polkadot JS extension installed, please click on this
          <Styled.WalletMenuNoWalletLink href={POLKADOT_EXTENSION}>
            {' '}
            Link
          </Styled.WalletMenuNoWalletLink>
          .
        </Styled.WalletMenuNoWallet>
      ) : null}
      {accounts &&
        accounts.length > 0 &&
        accounts?.map((account) => {
          const selected = selectedAccount?.address === account?.address;

          return (
            <Styled.WalletMenuItemContainer
              key={account?.address}
              selected={selected}
              onClick={() => {
                saveSelectedAccount(account);
                closeMenu();
              }}
            >
              <Styled.CeresLogoContainer>
                <Styled.WalletMenuAvatarTitle>
                  {getAvatarTitle(account?.meta?.name)}
                </Styled.WalletMenuAvatarTitle>
              </Styled.CeresLogoContainer>
              <SpacerVertical size={15} />
              <Styled.WalletMenuItemInfoContainer>
                <Styled.WalletMenuUserTitle>
                  {account?.meta?.name}
                </Styled.WalletMenuUserTitle>
                <Styled.WalletMenuUserAddress>
                  {formatWalletAddress(account?.address)}
                </Styled.WalletMenuUserAddress>
              </Styled.WalletMenuItemInfoContainer>
            </Styled.WalletMenuItemContainer>
          );
        })}
    </Styled.WalletMenuContainer>
  );
};

const NetworkMenu = ({
  networkOptions,
  network,
  setWalletSelectDisabled,
  switchNetwork,
  closeNetworkMenu,
}) => {
  const isMobileS = useMediaQuery({ query: device.mobileS });

  return (
    <Styled.NetworkMenuContainer isMobileS={isMobileS}>
      <Styled.WalletMenuTitle>Network</Styled.WalletMenuTitle>
      <SpacerHorizontal size={25} />
      {networkOptions?.map((n) => {
        const selected = n.id === network.id;

        return (
          <Styled.WalletMenuItemContainer
            key={n.id}
            selected={selected}
            onClick={async () => {
              setWalletSelectDisabled(true);
              await switchNetwork(n);
              setWalletSelectDisabled(false);
              closeNetworkMenu();
            }}
          >
            <Styled.CeresLogoContainer>
              <Styled.NetworkLogo src={n.logo} />
            </Styled.CeresLogoContainer>
            <SpacerVertical size={15} />
            <Styled.WalletMenuItemInfoContainer>
              <Styled.NetworkMenuUserTitle>
                {n.name}
              </Styled.NetworkMenuUserTitle>
            </Styled.WalletMenuItemInfoContainer>
          </Styled.WalletMenuItemContainer>
        );
      })}
    </Styled.NetworkMenuContainer>
  );
};

export default Header;
