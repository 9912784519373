import React, { useState } from 'react';
import Header from '../../components/header/header';
import Sidebar from '../../components/sidebar/sidebar';

import * as Styled from './main_layout.styled';

import { useMediaQuery } from 'react-responsive';
import { device } from '../../styles/device';

const MainLayout = ({ children }) => {
  const isLaptop = useMediaQuery({ query: device.laptop });

  const [sidebarOpen, setSidebarOpen] = useState(!isLaptop);

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <Styled.Container>
      <Header
        sidebarOpen={sidebarOpen}
        openSidebar={openSidebar}
        closeSidebar={closeSidebar}
      />
      <Styled.LayoutContainer>
        <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
        <Styled.MainContentContainer>{children}</Styled.MainContentContainer>
      </Styled.LayoutContainer>
    </Styled.Container>
  );
};

export default MainLayout;
