// GLOBAL //
export const APP_NAME = 'Ceres Platforms';
export const TOKEN_NAME = 'Ceres';
export const SORA_TOKEN = 'XOR';
export const SITE_URL = 'https://dapps.cerestoken.io';
export const CERES_ADDRESS =
  '0x008bcfd2387d3fc453333557eecb0efe59fcba128769b2feefdd306e98e66440';
export const DAI_ADDRESS =
  '0x0200060000000000000000000000000000000000000000000000000000000000';

// SORA API //
export const SORA_API_TEST =
  'wss://ws.framenode-3.s2.stg1.sora2.soramitsu.co.jp';
export const SORA_API = 'wss://ws.mof.sora.org';
export const POLKADOT_ACCOUNT = 'DAPPS_POLKADOT_ACCOUNT';

// ASTAR //
export const ASTAR_CHAIN_ID = '0x250';
export const SHIBUYA_CHAIN_ID = '0x51';

// CERES API //
export const PAIRS_API = 'https://data.cerestoken.io/api/pairs';
export const TOKENS_API = 'https://data.cerestoken.io/api/prices';
export const IMAGE_STORAGE = 'https://data.cerestoken.io/storage/icons/';
export const GOVERNANCE_ACTIVE_POLLS =
  'https://api.cerestoken.io/api/polls/active';
export const GOVERNANCE_UPCOMING_POLLS =
  'https://api.cerestoken.io/api/polls/upcoming';
export const GOVERNANCE_CLOSED_POLLS =
  'https://api.cerestoken.io/api/polls/closed';
export const GOVERNANCE_POOL = 'https://api.cerestoken.io/api/polls';
export const KYC_VERIFICATION_API =
  'https://api.cerestoken.io/api/tokens/verification/access-token';
export const LAUNCHPAD_LIST = 'https://api.cerestoken.io/api/tokens/ilo';
export const LAUNCHPAD = 'https://api.cerestoken.io/api/tokens';

// ASTAR API //
export const ASTAR_API =
  'https://api.cerestoken.io/api/lock/pairs/astar-locker';

// EXTERNAL LINKS //
export const POLKASWAP = 'https://polkaswap.io/';
export const ARTHSWAP = 'https://app.arthswap.org/';
export const CERES_TELEGRAM = 'https://t.me/cerestoken';
export const CERES_TWITTER = 'https://twitter.com/TokenCeres';
export const CERES_MEDIUM = 'https://tokenceres.medium.com/';
export const CERES_TOOLS = 'https://tools.cerestoken.io/';
export const DEMETER_FARMING = 'https://farming.deotoken.io/';
export const DEO_ARENA = 'https://deoarena.io/';
export const POLKADOT_EXTENSION = 'https://polkadot.js.org/extension/';
export const METAMASK_EXTENSION = 'https://metamask.io/download/';
export const ILO_GUIDE =
  'https://ceres-token.s3.eu-central-1.amazonaws.com/docs/Ceres%2BLaunchpad%2B-%2BCreate%2BILO%2BGuide.pdf';

// ROUTE PERMALINKS //
export const HOME_PERMALINK = '/';
export const LIQUIDITY_LOCKER_PERMALINK = '/liquidity_locker';
export const TOKEN_LOCKER_PERMALINK = '/token_locker';
export const LAUNCHPAD_PERMALINK = '/launchpad';
export const GOVERNANCE_PERMALINK = '/governance';
export const STAKING_PERMALINK = '/staking';

// SIDE MENU ITEMS //
export const HOME_PAGE = 'Home';
export const LIQUIDITY_LOCKER_PAGE = 'Liquidity Locker';
export const TOKEN_LOCKER_PAGE = 'Token Locker';
export const LAUNCHPAD_PAGE = 'Launchpad';
export const GOVERNANCE_PAGE = 'Governance';
export const STAKING_PAGE = 'Staking';
export const TOOLS_PAGE = 'Tools';
export const DEOARENA_PAGE = 'DEO Arena';
export const FARMING_PAGE = 'Demeter Farming';

// TOAST ID //
export const TOAST_ID = 'ToastID';

// INPUT ERROR MESSAGES //
export const REQUIRED_FIELD = 'This field is required!';
export const NOT_A_NUMBER = 'You must enter a number value';
export const WALLET_NOT_CONNECTED = 'Wallet not connected';
export const GREATER_THAN_ZERO = 'You must enter a number greater than 0';

// LIQUIDITY LOCKER //
export const TAB_DESCRIPTION =
  'Project teams are able to lock their tokens liquidity and ensure that it cannot be pulled out for some period giving investors proof of security and seriousness of the project.';
export const LIQUIDITY_ERROR =
  'Percentage of liquidity must be greater than 0 and less or equal than (100 - Locked liquidity).';
export const LIQUIDITY_ERROR_ASTAR =
  'Number of LP Tokens must be greater than 0 and less or equal than LP Balance.';

// TOKEN LOCKER //
export const TOKEN_LOCKER_TAB_DESCRIPTION =
  'Project teams are able to lock their team/marketing/other tokens for some period in order to ensure investors that those tokens will not be sold.';
export const TOKEN_LOCKER_ERROR =
  'Number of tokens must be greater than 0 and Balance must be greater or equal than (Amount + Fee).';

// SOCIALS //
export const LINKEDIN = 'LinkedIn';
export const TELEGRAM = 'Telegram';
export const TWITTER = 'Twitter';

// KYC //
export const WHITELIST_ORGANIZER =
  'Your wallet address is not whitelisted. If you want to create an ILO, contact one of admins on Telegram.';
export const WHITELIST_CONTRIBUTOR =
  'Your wallet address is not whitelisted. If you want to participate in some ILO, you need to do a KYC verification.';

// WALLET //
export const WALLET_DISABLED = 'You cannot change account on this page.';

// NETWORK //
export const SORA = 'SORA';
export const ASTAR = 'ASTAR';
