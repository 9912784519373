import * as Styled from './sidebar.styled';
import { useMediaQuery } from 'react-responsive';

import Grain from '../../assets/svg/grain.svg';
import Polkaswap from '../../assets/svg/polkaswap.svg';

import { device } from '../../styles/device';
import {
  sidebarItems,
  sidebarItemsAstar,
  sidebarSocials,
} from './sidebar.helper';
import { POLKASWAP, SORA } from '../../constants';

import { useLocation } from 'react-router-dom';
import { getActivePermalink } from '../../utils/helpers';
import usePolkadot from 'hooks/use_polkadot';

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
  const location = useLocation();

  const { network } = usePolkadot();

  const isLaptop = useMediaQuery({ query: device.laptop });

  const renderSidebarItem = (isActive, item) => (
    <>
      <Styled.SidebarItemIconContainer selected={isActive}>
        {item.ReactIcon ? (
          item.ReactIcon
        ) : (
          <Styled.SidebarItemIcon src={item.icon} />
        )}
      </Styled.SidebarItemIconContainer>
      <Styled.Text>
        {item.title}
        {!item.live ? <Styled.Soon>SOON</Styled.Soon> : null}
      </Styled.Text>
    </>
  );

  const renderSideBar = () => {
    const items = network.id === SORA ? sidebarItems : sidebarItemsAstar;

    return (
      <Styled.SidebarWrapper image={Grain}>
        <Styled.SidebarContainer>
          <Styled.SidebarItemsContainer>
            {items.map((item) => {
              const isActive = getActivePermalink(
                location?.pathname,
                item.permalink
              );

              if (item.external) {
                return (
                  <Styled.SidebarItemLink key={item.id} href={item.permalink}>
                    {renderSidebarItem(isActive, item)}
                  </Styled.SidebarItemLink>
                );
              }

              return (
                <Styled.SidebarItem
                  key={item.id}
                  to={item.live ? item.permalink : ''}
                  selected={isActive}
                >
                  {renderSidebarItem(isActive, item)}
                </Styled.SidebarItem>
              );
            })}
          </Styled.SidebarItemsContainer>
          <Styled.SidebarFooterContainer>
            <Styled.Divider />
            {network.id === SORA && (
              <Styled.Link href={POLKASWAP}>
                <Styled.PolkaswapLogo src={Polkaswap} />
              </Styled.Link>
            )}
            <Styled.FollowText>Follow us</Styled.FollowText>
            <Styled.SidebarFooterSocialsContainer>
              {sidebarSocials.map((item) => (
                <Styled.Link key={item.id} href={item.link}>
                  <Styled.SidebarFooterSocialItemContainer key={item.id}>
                    <Styled.SidebarItemIcon src={item.icon} />
                  </Styled.SidebarFooterSocialItemContainer>
                </Styled.Link>
              ))}
            </Styled.SidebarFooterSocialsContainer>
          </Styled.SidebarFooterContainer>
        </Styled.SidebarContainer>
      </Styled.SidebarWrapper>
    );
  };

  if (!isLaptop) {
    return renderSideBar();
  }

  return (
    <Styled.SidebarWrapperLaptop sidebarOpen={sidebarOpen}>
      {renderSideBar()}
      <Styled.SidebarBackdrop onClick={() => closeSidebar()} />
    </Styled.SidebarWrapperLaptop>
  );
};

export default Sidebar;
