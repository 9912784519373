import React, {
  createContext,
  useEffect,
  useCallback,
  useRef,
  useState,
} from 'react';

import { WsProvider } from '@polkadot/rpc-provider/ws';
import { ApiPromise } from '@polkadot/api';
import { options } from '@sora-substrate/api';
import { web3Accounts, web3Enable } from '@polkadot/extension-dapp';

import { ethers } from 'ethers';

import Sora from '../assets/svg/sora.svg';
import Astar from '../assets/svg/astar.svg';

import {
  APP_NAME,
  ASTAR,
  CERES_ADDRESS,
  DAI_ADDRESS,
  METAMASK_EXTENSION,
  POLKADOT_ACCOUNT,
  ASTAR_CHAIN_ID,
  SORA,
  SORA_API,
  ASTAR_API,
  // SORA_API_TEST,
} from '../constants';

import multipliers from '../utils/multipliers';
import usePersistState from '../hooks/use_persist_state';
import { generateErrorMessage } from 'utils/helpers';
import axios from 'axios';

const customTypes = {
  PoolData: {
    multiplier: 'u32',
    deposit_fee: 'Balance',
    is_core: 'bool',
    is_farm: 'bool',
    total_tokens_in_pool: 'Balance',
    rewards: 'Balance',
    rewards_to_be_distributed: 'Balance',
    is_removed: 'bool',
  },
  TokenInfo: {
    farms_total_multiplier: 'u32',
    staking_total_multiplier: 'u32',
    token_per_block: 'Balance',
    farms_allocation: 'Balance',
    staking_allocation: 'Balance',
    team_allocation: 'Balance',
    team_account: 'AccountId',
  },
  UserInfo: {
    pool_asset: 'AssetId',
    reward_asset: 'AssetId',
    is_farm: 'bool',
    pooled_tokens: 'Balance',
    rewards: 'Balance',
  },
  LockInfo: {
    pool_tokens: 'Balance',
    unlocking_timestamp: 'Moment',
    asset_a: 'AssetId',
    asset_b: 'AssetId',
  },
  TokenLockInfo: {
    tokens: 'Balance',
    unlocking_timestamp: 'Moment',
    asset_id: 'AssetId',
  },
  StakingInfo: {
    deposited: 'Balance',
    rewards: 'Balance',
  },
  VotingInfo: {
    voting_option: 'u32',
    number_of_votes: 'Balance',
    ceres_withdrawn: 'bool',
  },
  PollInfo: {
    number_of_options: 'u32',
    poll_start_timestamp: 'Moment',
    poll_end_timestamp: 'Moment',
  },
  ILOInfo: {
    ilo_organizer: 'AccountId',
    tokens_for_ilo: 'Balance',
    tokens_for_liquidity: 'Balance',
    ilo_price: 'Balance',
    soft_cap: 'Balance',
    hard_cap: 'Balance',
    min_contribution: 'Balance',
    max_contribution: 'Balance',
    refund_type: 'bool',
    liquidity_percent: 'Balance',
    listing_price: 'Balance',
    lockup_days: 'u32',
    start_timestamp: 'Moment',
    end_timestamp: 'Moment',
    contributors_vesting: 'ContributorsVesting',
    team_vesting: 'TeamVesting',
    sold_tokens: 'Balance',
    funds_raised: 'Balance',
    succeeded: 'bool',
    failed: 'bool',
    lp_tokens: 'Balance',
    claimed_lp_tokens: 'bool',
    finish_timestamp: 'Moment',
  },
  TeamVesting: {
    team_vesting_total_tokens: 'Balance',
    team_vesting_first_release_percent: 'Balance',
    team_vesting_period: 'Moment',
    team_vesting_percent: 'Balance',
  },
  ContributorsVesting: {
    first_release_percent: 'Balance',
    vesting_period: 'Moment',
    vesting_percent: 'Balance',
  },
};

const astarNetworkParams = [
  {
    chainId: '0x250',
    chainName: 'Astar Network',
    nativeCurrency: {
      name: 'Astar',
      symbol: 'ASTR',
      decimals: 18,
    },
    rpcUrls: ['https://rpc.astar.network:8545'],
    blockExplorerUrls: ['https://blockscout.com/astar'],
    iconUrls: [''],
  },
];

const networkOptions = [
  { id: SORA, name: 'SORA', logo: Sora },
  { id: ASTAR, name: 'Astar', logo: Astar },
];

export const PolkadotContext = createContext();

export const PolkadotProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [network, setNetwork] = usePersistState(
    networkOptions[0],
    'DAPPS_NETWORK'
  );
  const [ceresPrice, setCeresPrice] = useState(null);

  const api = useRef(null);
  const ceresPriceInterval = useRef(null);

  const astarProvider = useRef(null);

  const doneLoading = useCallback(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);

  const saveSelectedAccount = useCallback(
    async (account) => {
      if (account !== selectedAccount) {
        if (network.id === SORA) {
          localStorage.setItem(POLKADOT_ACCOUNT, JSON.stringify(account));
          setSelectedAccount(account);
        } else {
          astarProvider.current = account;
          const address = await account?.getSigner()?.getAddress();
          localStorage.setItem(POLKADOT_ACCOUNT, JSON.stringify(address));
          setSelectedAccount({ address });
          doneLoading();
        }
      }
    },
    [selectedAccount, network, doneLoading]
  );

  const deleteSelectedAccount = () => {
    localStorage.removeItem(POLKADOT_ACCOUNT);
    setSelectedAccount(null);
    setAccounts(null);
    astarProvider.current = null;
  };

  const handleChainChanged = useCallback((chainId) => {
    if (chainId !== ASTAR_CHAIN_ID) {
      deleteSelectedAccount();
    }
  }, []);

  const handleAccountChanged = useCallback((accounts) => {
    localStorage.setItem(POLKADOT_ACCOUNT, JSON.stringify(accounts[0]));
    setSelectedAccount({ address: accounts[0] });
  }, []);

  const setApi = useCallback(async () => {
    /** Connect to Sora network **/
    const provider = new WsProvider(SORA_API);

    const soraAPI = new ApiPromise(options({ types: customTypes, provider }));

    await soraAPI.isReady;
    api.current = soraAPI;
  }, []);

  const connectToPolkadotExtension = useCallback(async () => {
    const accountJSON = localStorage.getItem(POLKADOT_ACCOUNT);
    const account = JSON.parse(accountJSON) || null;

    // this call fires up the authorization popup
    const extensions = await web3Enable(APP_NAME);

    if (extensions.length !== 0) {
      // we are now informed that the user has at least one extension and that we
      // will be able to show and use accounts
      const allAccounts = await web3Accounts();

      if (allAccounts !== null && allAccounts.length > 0) {
        setAccounts(allAccounts);

        if (account !== null) {
          const accountsFiltered = allAccounts.filter(
            (acc) => acc?.meta?.name === account?.meta?.name
          );
          if (accountsFiltered.length > 0) {
            setSelectedAccount(account);
          }
        }
      }
    }
  }, []);

  const connectToMetamaskAccount = useCallback(async () => {
    try {
      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        'any'
      ); // astar -> 592
      await provider.send('eth_requestAccounts', []);

      // The MetaMask plugin also allows signing transactions to
      // send ether and pay to change state within the blockchain.
      // For this, you need the account signer...
      saveSelectedAccount(provider);
    } catch (error) {
      doneLoading();
      generateErrorMessage(error);
    }
  }, [saveSelectedAccount, doneLoading]);

  const connectToMetamask = useCallback(async () => {
    if (!window.ethereum) {
      doneLoading();
      window.open(METAMASK_EXTENSION, '_blank');
    } else {
      try {
        await window.ethereum?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: ASTAR_CHAIN_ID }],
        });
        connectToMetamaskAccount();
      } catch (error) {
        if (error.code === 4902) {
          try {
            await window.ethereum?.request({
              method: 'wallet_addEthereumChain',
              params: astarNetworkParams,
            });
            connectToMetamaskAccount();
          } catch (addError) {
            doneLoading();
            generateErrorMessage(error);
          }
        }
      }
    }
  }, [connectToMetamaskAccount, doneLoading]);

  const setMetamaskListener = useCallback(async () => {
    try {
      window.ethereum.on('chainChanged', handleChainChanged);
      window.ethereum.on('accountsChanged', handleAccountChanged);
    } catch {}
  }, [handleChainChanged, handleAccountChanged]);

  /** Calculate price **/
  const calculatePrice = (price) => {
    try {
      return (
        parseFloat(price.split(' ')[0]) *
        multipliers[price.split(' ')[1].toString()]
      ).toFixed(4);
    } catch {
      return '0.00';
    }
  };

  const getCeresPrice = useCallback(
    async (n) => {
      if (n.id === SORA) {
        await api.current?.rpc?.liquidityProxy?.quote(
          0,
          CERES_ADDRESS,
          DAI_ADDRESS,
          '1000000000000000000',
          'WithDesiredInput',
          ['XYKPool'],
          'Disabled',
          (price) => {
            price = price.toHuman();
            if (price != null) {
              setCeresPrice(calculatePrice(price['amount']));
            }
          }
        );
      } else {
        const config = {
          method: 'get',
          url: ASTAR_API,
          headers: {},
        };

        return axios(config)
          .then((response) => {
            if (response.status === 200) {
              setCeresPrice(response.data.ceresPrice?.toFixed(4));
            }
          })
          .catch(() => {});
      }
    },
    [] // api
  );

  // const setPriceInterval = useCallback(async () => {
  //   ceresPriceInterval.current = setInterval(() => {
  //     getCeresPrice();
  //   }, 30000);
  // }, [getCeresPrice]);

  const init = useCallback(
    async (n) => {
      setCeresPrice(0);

      if (n.id === SORA) {
        await setApi();
        await getCeresPrice(n);
        await connectToPolkadotExtension();
        // setPriceInterval();
        setLoading(false);
      } else {
        getCeresPrice(n);
        // setPriceInterval();
        if (localStorage.getItem(POLKADOT_ACCOUNT)) {
          await connectToMetamask();
        } else {
          setLoading(false);
        }
        setMetamaskListener();
      }
    },
    [
      setApi,
      connectToPolkadotExtension,
      connectToMetamask,
      setMetamaskListener,
      getCeresPrice,
      // setPriceInterval,
    ]
  );

  const clear = useCallback(() => {
    clearInterval(ceresPriceInterval.current);
    api.current?.disconnect();
    api.current = null;
    window.ethereum?.removeListener('chainChanged', handleChainChanged);
    window.ethereum?.removeListener('accountsChanged', handleAccountChanged);
  }, [handleChainChanged, handleAccountChanged]);

  const switchNetwork = useCallback(
    async (n) => {
      if (network.id !== n.id) {
        clear();
        deleteSelectedAccount();
        await init(n);
        setNetwork(n);
      }
    },
    [network, init, setNetwork, clear]
  );

  useEffect(() => {
    init(network);

    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PolkadotContext.Provider
      value={{
        api: api.current,
        provider: astarProvider.current,
        accounts,
        selectedAccount,
        saveSelectedAccount,
        networkOptions,
        network,
        switchNetwork,
        ceresPrice,
        loading,
        connectToMetamask,
      }}
    >
      {children}
    </PolkadotContext.Provider>
  );
};
