import { createGlobalStyle } from 'styled-components';

import { colors } from './colors';

const GlobalStyle = createGlobalStyle`
    *,
    *:before,
    *:after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        -webkit-tap-highlight-color: transparent;
    }

    body {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-size: 14px;
        background: ${colors.blueBackgroundDark};
    }

    :root {
        --toastify-spinner-color: ${colors.white};
        --toastify-spinner-color-empty-area: ${colors.whiteOpacity};
        --toastify-color-dark: ${colors.toastBG};
    }

    button, input, select, textarea {
        font-family: inherit !important;
        box-shadow: none !important;
        border: none !important
    }

    input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

    input[type=number] {
    -moz-appearance: textfield;
    }

    input[type="file"] {
        display: none;
    }

    @media only screen and (min-width: 600px) {
        *,
        *:before,
        *:after {
            scrollbar-color: ${colors.teal} ${colors.scrollbar_bg};
            scrollbar-width: thin;
        }
        *::-webkit-scrollbar {
            width: 12px;
        }
        *::-webkit-scrollbar-track {
            background: ${colors.scrollbar_bg};
        }
        *::-webkit-scrollbar-thumb {
            background-color: ${colors.teal};
            border-radius: 6px;
            border: 3px solid ${colors.scrollbar_bg};
        }
    }

    @media only screen and (max-width: 1024px) {
        body {
            overflow: hidden;
        }
    }

    .react-modal-overlay {
        position: fixed;
        inset: 0px;
        background-color: #00000060 !important;
    }

    .tabs-container {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .tab {
        display: inline-block;
        border: 1px solid transparent;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 8px 15px;
        cursor: pointer;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: rgba(255, 255, 255, 0.4);
    }

    .tab-selected {
        background-color: transparent;
        color: ${colors.white};
        border-bottom: 1px solid ${colors.teal};
    }

    /*
	*
	* react-toastify styles
	*
	*/
    .Toastify__toast-container {
        max-width: 80vw;
        width: auto;
    }
    .Toastify__toast-body {
        padding: 15px;
    }
    .Toastify__toast-body>div:last-child {
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: ${colors.white};
    }
    .Toastify__toast--success {
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 16px;
        background: ${colors.toastBG};
        color: ${colors.white};
    }
    .Toastify__toast--loading {
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 16px;
        background: ${colors.toastBG};
        color: ${colors.white};
    }
    .Toastify__toast--error {
        font-family: Montserrat, sans-serif;
        font-weight: 600;
        font-size: 16px;
        background: ${colors.toastBG};
        color: ${colors.white};
    }
    .Toastify__close-button {
        color: ${colors.white} !important;
    }

    /*
	*
	* react-datepicker styles
	*
	*/

    .datepicker_wrapper {
        height: 70px;
        width: 100%;
        border-radius: 14px;
        overflow: hidden;
        background-color: #ededed10;
    }
    .datepicker_border {
        border: 1px solid ${colors.redError} !important;
    }
    .datepicker_small {
        height: 45px;
    }
    .datepicker_input {
        width: 100%;
        height: 70px;
        background-color: transparent;
        border-radius: 14px;
        padding: 0 20px;
        color: ${colors.white};
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
        font-family: Montserrat, sans-serif !important;
    }
    .datepicker_input_small {
        height: 45px;
        font-size: 12px;
    }
    .react-datepicker-wrapper input[type="text"]{
        border: none !important;
        outline: none !important;
    }
    .react-datepicker-wrapper input[type="text"]::placeholder {
        color: #ffffff70;
        opacity: 1;
    }
    .react-datepicker-wrapper input[type="text"]:-ms-input-placeholder {
        color: #ffffff70;
    }
    .react-datepicker-wrapper input[type="text"]::-ms-input-placeholder {
        color: #ffffff70;
    }
    .react-datepicker {
        background: rgba(22, 18, 56, 1) !important;
        border: none !important;
        font-family: Montserrat, sans-serif !important;
    }
    .react-datepicker__header {
        background-color: transparent !important;
        border-bottom: 1px solid rgba(224, 225, 226, 0.1) !important;
    }
    .react-datepicker__time {
        background-color: transparent !important;
    }
    .react-datepicker__time-container {
        border-left: 1px solid rgba(224, 225, 226, 0.1) !important;
    }
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        border-bottom-color: rgba(22, 18, 56, 1) !important;
    }
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
        border-top-color: rgba(22, 18, 56, 1) !important;
    }
    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
        color: ${colors.white} !important;
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        color: ${colors.whiteOpacity} !important;
    }
    .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
        color: #ffffff10 !important;
    }
    .react-datepicker__day:hover {
        background-color: #ffffff10 !important;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
        color: ${colors.whiteOpacity} !important;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
        color: #ffffff10 !important;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
        background-color: #ffffff10 !important;
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__day--selected {
        background: ${colors.tealGradient} !important;
        color: ${colors.white} !important;
        font-weight: bold;
    }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
        background: ${colors.tealGradient} !important;
        color: ${colors.white} !important;
    }
    .react-datepicker__year-select {
        background-color: rgba(22, 18, 56, 1) !important;
        color: ${colors.white} !important;
    }
    .react-datepicker__year-select:focus-visible {
        outline: none !important;
    }
    .react-datepicker__year-dropdown {
        background-color: rgba(22, 18, 56, 1) !important;
        border: 1px solid #ffffff10 !important;
    }
    .react-datepicker__year-option {
        color: ${colors.whiteOpacity} !important;
    }
    .react-datepicker__year-option:hover {
        background-color: #ffffff10 !important;
    }
    .react-datepicker__year-dropdown-container--scroll {
        margin: 10px 2px !important;
    }
    .react-datepicker__year-read-view--selected-year {
        color: ${colors.white} !important;
        font-weight: bold;
    }

    /*
	*
	* react-share styles
	*
	*/

    .share-button {
        display: inline-flex;
        align-items: center;
        padding: 10px 20px !important;
    }

    .share-button:hover {
        background: #ffffff10 !important;
    }

    /*
	*
	* SumsubWebSdk styles
	*
	*/

    .sumsub {
        margin: 30px 30px 30px 0;
    }

    @media only screen and (max-width: 1280px) {
        .sumsub {
            margin: 30px 30px;
        }
    }
`;

export default GlobalStyle;
