import HomeIcon from '../../assets/svg/home.svg';
import LiquidityLockerIcon from '../../assets/svg/liquidity_locker_icon.svg';
import TockenLockerIcon from '../../assets/svg/token_locker.svg';
import LaunchpadIcon from '../../assets/svg/launchpad.svg';
import GovernanceIcon from '../../assets/svg/governance.svg';
import StakingIcon from '../../assets/svg/staking.svg';
import ToolsIcon from '../../assets/svg/tools.svg';

import TelegramIcon from '../../assets/svg/telegram.svg';
import TwitterIcon from '../../assets/svg/twitter.svg';
import MediumIcon from '../../assets/svg/medium.svg';

import { IoGameControllerOutline } from 'react-icons/io5';
import { GiGrainBundle } from 'react-icons/gi';

import {
  CERES_MEDIUM,
  CERES_TELEGRAM,
  CERES_TOOLS,
  CERES_TWITTER,
  DEMETER_FARMING,
  DEOARENA_PAGE,
  DEO_ARENA,
  FARMING_PAGE,
  GOVERNANCE_PAGE,
  GOVERNANCE_PERMALINK,
  HOME_PAGE,
  HOME_PERMALINK,
  LAUNCHPAD_PAGE,
  LAUNCHPAD_PERMALINK,
  LIQUIDITY_LOCKER_PAGE,
  LIQUIDITY_LOCKER_PERMALINK,
  STAKING_PAGE,
  STAKING_PERMALINK,
  TOKEN_LOCKER_PAGE,
  TOKEN_LOCKER_PERMALINK,
  TOOLS_PAGE,
} from '../../constants';

import { colors } from '../../styles/colors';

export const sidebarItems = [
  {
    id: '1',
    title: HOME_PAGE,
    permalink: HOME_PERMALINK,
    icon: HomeIcon,
    live: true,
  },
  {
    id: '2',
    title: LIQUIDITY_LOCKER_PAGE,
    permalink: LIQUIDITY_LOCKER_PERMALINK,
    icon: LiquidityLockerIcon,
    live: true,
  },
  {
    id: '3',
    title: TOKEN_LOCKER_PAGE,
    permalink: TOKEN_LOCKER_PERMALINK,
    icon: TockenLockerIcon,
    live: true,
  },
  {
    id: '4',
    title: LAUNCHPAD_PAGE,
    permalink: LAUNCHPAD_PERMALINK,
    icon: LaunchpadIcon,
    live: true,
  },
  {
    id: '5',
    title: GOVERNANCE_PAGE,
    permalink: GOVERNANCE_PERMALINK,
    icon: GovernanceIcon,
    live: true,
  },
  {
    id: '6',
    title: STAKING_PAGE,
    permalink: STAKING_PERMALINK,
    icon: StakingIcon,
    live: true,
  },
  {
    id: '7',
    title: TOOLS_PAGE,
    permalink: CERES_TOOLS,
    icon: ToolsIcon,
    external: true,
    live: true,
  },
  {
    id: '8',
    title: FARMING_PAGE,
    permalink: DEMETER_FARMING,
    external: true,
    live: true,
    ReactIcon: <GiGrainBundle size={22} color={colors.white} />,
  },
  {
    id: '9',
    title: DEOARENA_PAGE,
    permalink: DEO_ARENA,
    external: true,
    live: true,
    ReactIcon: <IoGameControllerOutline size={22} color={colors.white} />,
  },
];

export const sidebarItemsAstar = [
  {
    id: '1',
    title: HOME_PAGE,
    permalink: HOME_PERMALINK,
    icon: HomeIcon,
    live: true,
  },
  {
    id: '2',
    title: LIQUIDITY_LOCKER_PAGE,
    permalink: LIQUIDITY_LOCKER_PERMALINK,
    icon: LiquidityLockerIcon,
    live: true,
  },
  {
    id: '3',
    title: TOKEN_LOCKER_PAGE,
    permalink: TOKEN_LOCKER_PERMALINK,
    icon: TockenLockerIcon,
    live: true,
  },
];

export const sidebarSocials = [
  { id: '1', link: CERES_TELEGRAM, icon: TelegramIcon },
  { id: '2', link: CERES_TWITTER, icon: TwitterIcon },
  { id: '3', link: CERES_MEDIUM, icon: MediumIcon },
];
