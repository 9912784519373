import React, { Suspense, lazy } from 'react';

import usePolkadot from '../hooks/use_polkadot';

import { Routes, Route } from 'react-router-dom';
import PageLoader from '../components/page_loader/page_loader';
import {
  GOVERNANCE_PERMALINK,
  HOME_PERMALINK,
  LAUNCHPAD_PERMALINK,
  LIQUIDITY_LOCKER_PERMALINK,
  SORA,
  STAKING_PERMALINK,
  TOKEN_LOCKER_PERMALINK,
} from '../constants';
import MainLayout from 'layouts/main_layout/main_layout';

const Home = lazy(() => import('../pages/home/home'));
const LiquidityLocker = lazy(() =>
  import('../pages/liquidity_locker/liquidity_locker')
);
const TokenLocker = lazy(() => import('../pages/token_locker/token_locker'));
const Launchpad = lazy(() => import('../pages/launchpad/launchpad'));
const LaunchpadCreate = lazy(() =>
  import('../pages/launchpad/launchpad_create')
);
const LaunchpadDetails = lazy(() =>
  import('../pages/launchpad/launchpad_details')
);
const LaunchpadKYC = lazy(() => import('../pages/launchpad/launchpad_kyc'));
const Governance = lazy(() => import('../pages/governance/governance'));
const GovernanceDetails = lazy(() =>
  import('../pages/governance/governance_details')
);
const Staking = lazy(() => import('../pages/staking/staking'));
const RouteGuard = lazy(() => import('../layouts/route_guard/route_guard'));

const Router = () => {
  const { network } = usePolkadot();

  return (
    <MainLayout>
      <Suspense fallback={<PageLoader fullscreen />}>
        <Routes>
          <Route exact path={HOME_PERMALINK} element={<Home />} />
          <Route
            exact
            path={LIQUIDITY_LOCKER_PERMALINK}
            element={<LiquidityLocker />}
          />
          <Route
            exact
            path={TOKEN_LOCKER_PERMALINK}
            element={<TokenLocker />}
          />
          {network.id === SORA && (
            <Route exact path={LAUNCHPAD_PERMALINK} element={<Launchpad />}>
              <Route
                path={`${LAUNCHPAD_PERMALINK}/:token`}
                element={<LaunchpadDetails />}
              />
              <Route
                exact
                path={`${LAUNCHPAD_PERMALINK}/create`}
                element={<LaunchpadCreate />}
              />
              <Route
                exact
                path={`${LAUNCHPAD_PERMALINK}/kyc`}
                element={<LaunchpadKYC />}
              />
            </Route>
          )}
          {network.id === SORA && (
            <Route path={GOVERNANCE_PERMALINK} element={<Governance />}>
              <Route
                path={`${GOVERNANCE_PERMALINK}/:id`}
                element={<GovernanceDetails />}
              />
            </Route>
          )}
          <Route exact path={STAKING_PERMALINK} element={<Staking />} />
          <Route path='*' element={<RouteGuard />} />
        </Routes>
      </Suspense>
    </MainLayout>
  );
};

export default Router;
