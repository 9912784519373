import { toast } from 'react-toastify';

export const showSuccessNotify = (msg, toastId = null) =>
  toast.success(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    toastId,
  });

export const showErrorNotify = (msg, toastId = null) =>
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    toastId,
  });

export const showLoadingNotify = (msg = 'Processing...') =>
  toast.loading(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
  });

export const showUpdatedNotify = (id, msg, type) =>
  toast.update(id, {
    render: msg,
    type: type,
    autoClose: 5000,
    isLoading: false,
  });
