export const size = {
  mobileXS: '375px',
  mobileS: '480px',
  mobileM: '600px',
  mobileL: '768px',
  mobileXL: '992px',
  tablet: '1024px',
  laptop: '1280px',
  laptopL: '1440px',
  laptopXL: '1600px',
  desktop: '1800px',
};

export const device = {
  mobileXS: `(max-width: ${size.mobileXS})`,
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileXL: `(max-width: ${size.mobileXL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  laptopXL: `(max-width: ${size.laptopXL})`,
  desktop: `(max-width: ${size.desktop})`,
  portrait: 'orientation: portrait',
  landscape: 'orientation: landscape',
};
