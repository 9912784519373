import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import history from './utils/history';

import { PolkadotProvider } from './contexts/polkadot_context';

import { IntlProvider } from 'react-intl';

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale='en'>
      <BrowserRouter history={history}>
        <PolkadotProvider>
          <App />
        </PolkadotProvider>
      </BrowserRouter>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
