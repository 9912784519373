import React from 'react';

import Spinner from '../spinner/spinner';

import * as Styled from './page_loader.styled';

const PageLoader = ({ fullscreen = false }) => {
  return (
    <Styled.WrapperSpinner fullscreen={fullscreen}>
      <Spinner />
    </Styled.WrapperSpinner>
  );
};

export default PageLoader;
