import styled from 'styled-components/macro';
import { colors } from '../../styles/colors';
import { fontSizes, fontStyles } from '../../styles/fonts';
import { device } from '../../styles/device';

import { NavLink } from 'react-router-dom';

export const SidebarWrapperLaptop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000060 !important;
  z-index: 10;
  display: ${({ sidebarOpen }) => (sidebarOpen ? `flex` : `none`)};
`;

export const SidebarWrapper = styled.div`
  padding: 30px;
  width: 340px;
  height: 100%;
  background: ${({ image }) => `url(${image})  no-repeat`};
  background-position: center 150px;
  position: relative;

  &:before {
    background-image: radial-gradient(circle, #32ceff80, #6c9bf080);
    border-radius: 50%;
    content: '';
    filter: blur(150px);
    height: 60%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateX(-30%);
    width: 250px;
    z-index: -1;
  }

  @media ${device.laptop} {
    background: none;
    padding: 0;
    width: 280px;
    &:before {
      background-image: none;
    }
  }
`;

export const SidebarContainer = styled.div`
  height: 100%;
  width: 280px;
  background: rgba(20, 16, 54, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 18px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  @media ${device.laptop} {
    background: rgba(20, 16, 54, 1);
    backdrop-filter: none;
    border-radius: 0;
  }
`;

export const SidebarBackdrop = styled.div`
  flex: 1;
`;

export const SidebarItemsContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SidebarItem = styled(NavLink)`
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
  background: ${({ selected }) =>
    selected ? `${colors.tealGradient}` : `none`};
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: ${({ selected }) =>
      selected ? `${colors.tealGradient}` : `#ffffff10`};
  }
`;

export const SidebarItemLink = styled.a.attrs({
  target: 'blank',
})`
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
  background: ${({ selected }) =>
    selected ? `${colors.tealGradient}` : `none`};
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: ${({ selected }) =>
      selected ? `${colors.tealGradient}` : `#ffffff10`};
  }
`;

export const SidebarItemIconContainer = styled.div`
  width: 30px;
  height: 30px;
  background: ${({ selected }) => (selected ? `#1c315e` : `#ffffff15`)};
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SidebarItemIcon = styled.img``;

export const Text = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  color: ${colors.white};
  font-weight: 500;
  padding-left: 15px;
`;

export const Divider = styled.div`
  height: 2px;
  width: 100%;
  margin: 20px 0 10px 0;
  background: rgba(255, 255, 255, 0.06);
`;

export const SidebarFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Link = styled.a.attrs({
  target: 'blank',
})``;

export const PolkaswapLogo = styled.img`
  width: 130px;
`;

export const FollowText = styled.span`
  ${fontStyles[fontSizes.twelve]}
  font-weight: 500;
  line-height: 15px;
  color: ${colors.white};
  margin-top: 15px;
  margin-bottom: 10px;
`;

export const SidebarFooterSocialsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const SidebarFooterSocialItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  background: #ffffff10;
  box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin: 0 5px;
`;

export const Soon = styled.div`
  ${fontStyles[fontSizes.ten]};
  margin-left: 5px;
  display: inline-block;
  padding: 4px;
  border-radius: 5px;
  background: ${colors.tealGradient};
  color: white;
  font-weight: bold;
`;
