import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`;

export const LayoutContainer = styled.div`
  display: flex;
  height: calc(calc(var(--vh, 1vh) * 100) - 100px);
  overflow: hidden;
  position: relative;
`;

export const MainContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
