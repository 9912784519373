import styled from 'styled-components/macro';
import { colors } from '../../styles/colors';
import { fontSizes, fontStyles } from '../../styles/fonts';
import { device } from '../../styles/device';

export const Wrapper = styled.div`
  height: 100px;
  width: 100%;
  background: ${colors.headerBackground};
  border-bottom: 1px solid ${colors.headerBorder};
  display: flex;
  flex-direction: column;
  z-index: 10;
`;

export const CeresPriceContainer = styled.div`
  height: 20%;
  width: 100%;
  background: ${colors.tealGradient};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CeresPriceLabel = styled.label`
  ${fontStyles[fontSizes.twelve]}
  color: ${colors.white};
  font-weight: bold;
  text-transform: uppercase;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: inherit;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;

  @media ${device.mobileL} {
    height: 80%;
    padding: 0 20px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  min-width: 120px;
`;

export const MenuIcon = styled.img`
  cursor: pointer;
  min-width: 20px;
`;

export const Logo = styled.img`
  cursor: pointer;
`;

export const NetworkLogo = styled.img`
  width: 30px;
  height: auto;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 120px;
`;

export const CeresContainer = styled.div`
  background: ${colors.buttonColor};
  border-radius: 12px;
  padding: 5px 10px 5px 5px;
  display: flex;
  align-items: center;
  height: 55px;
`;

export const CeresLogoContainer = styled.div`
  width: 52px;
  height: 45px;
  background: ${colors.blueBackgroundDark};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileM} {
    width: 42px;
    height: 35px;
  }
`;

export const CeresPrice = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  padding-left: 10px;
  color: ${colors.white};
  font-weight: bold;
`;

export const CeresPriceSymbol = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  color: ${colors.whiteOpacity};
  font-weight: bold;
  padding-right: 5px;
`;

export const WalletButtonContainer = styled.button`
  background: ${colors.tealGradient};
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  height: 55px;

  @media ${device.mobileXL} {
    background: ${colors.buttonColor};
  }

  @media ${device.mobileM} {
    padding: 0 5px;
    height: 45px;
  }
`;

export const WalletButtonLabel = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.white};
`;

export const WalletButtonIcon = styled.img``;

export const WalletButtonContainerAccount = styled.button`
  background: ${colors.buttonColor};
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  height: 55px;

  @media ${device.mobileM} {
    padding: 0 5px;
    height: 45px;
  }
`;

export const WalletButtonAccountTitle = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  color: ${colors.white};
  font-weight: bold;
  line-height: 20px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${device.mobileXS} {
    ${fontStyles[fontSizes.ten]}
  }
`;

export const WalletButtonAccountArrow = styled.img`
  transform: ${({ visible }) => (visible ? `rotate(180deg)` : `rotate(0deg)`)};
`;

export const WalletMenuContainer = styled.div`
  width: 375px;
  background: rgba(25, 35, 76, 1);
  border-radius: 12px;
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 150px);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.mobileS} {
    width: calc(100vw - 40px);
  }
`;

export const NetworkMenuContainer = styled.div`
  width: 250px;
  background: rgba(25, 35, 76, 1);
  border-radius: 12px;
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 150px);

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const WalletMenuTitle = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  font-weight: bold;
  line-height: 20px;
  color: ${colors.white};
  margin-top: 10px;
`;

export const WalletMenuNoWallet = styled.span`
  ${fontStyles[fontSizes.fourteen]}
  color: ${colors.whiteOpacity};
  font-weight: normal;
`;

export const WalletMenuNoWalletLink = styled.a.attrs({
  target: 'blank',
})`
  color: ${colors.teal};
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
`;

export const WalletMenuItemContainer = styled.div`
  background: ${({ selected }) =>
    selected ? `${colors.tealGradient}` : `#15163b`};
  border-radius: 9px;
  padding: 0 15px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 75px;

  &:hover {
    background: ${({ selected }) =>
      selected ? `${colors.tealGradient}` : `#ffffff10`};
  }
`;

export const WalletMenuAvatarTitle = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  color: ${colors.white};
  font-weight: bold;
`;

export const WalletMenuItemInfoContainer = styled.div`
  display: inherit;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

export const WalletMenuUserTitle = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  color: ${colors.whiteOpacity};
  line-height: 20px;
  font-weight: 600;
`;

export const NetworkMenuUserTitle = styled.span`
  ${fontStyles[fontSizes.sixteen]}
  color: ${colors.white};
  line-height: 20px;
  font-weight: 600;
`;

export const WalletMenuUserAddress = styled.span`
  ${fontStyles[fontSizes.twelve]}
  color: ${colors.white};
  line-height: 15px;
  font-weight: bold;
`;
