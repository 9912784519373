export const border = `rgba(25, 35, 76)`;
export const teal = `#40DDFF`;
export const teal2 = `#40ddff`;
export const tealOpacity = `#40DDFF50`;
export const tealGradient = `linear-gradient(292.94deg, #40DDFF -2.02%, #1C3462 101.69%)`;
export const headerBackground = `rgba(20, 16, 54, 1)`;
export const headerBorder = `rgba(255, 255, 255, 0.1)`;
export const buttonColor = `#19234c`;
export const blueBackgroundDark = `#141036`;
export const white = `#ffffff`;
export const whiteOpacity = `rgba(255, 255, 255, 0.5)`;
export const black = `#000000`;
export const blackOpacity = `rgba(0, 0, 0, 0.7)`;
export const borderGrey = '#323d62';
export const grey = `#3D4A76`;
export const toastBG = `#040023`;
export const blueDark = `#130F33`;

export const circularProgressBar1 = `#20b8da`;
export const circularProgressBar2 = `#233f74`;

export const redError = `#e33a3a`;

export const scrollbar_bg = `rgba(25, 35, 76, 1)`;

export const launchpad_green_1 = `#3DEF9C`;
export const launchpad_green_2 = `#2E89B140`;

export const colors = {
  border,
  teal,
  teal2,
  tealOpacity,
  tealGradient,
  headerBackground,
  headerBorder,
  buttonColor,
  blueBackgroundDark,
  white,
  whiteOpacity,
  black,
  blackOpacity,
  borderGrey,
  grey,
  toastBG,
  circularProgressBar1,
  circularProgressBar2,
  redError,
  scrollbar_bg,
  blueDark,
  launchpad_green_1,
  launchpad_green_2,
};
